import { IActivity } from './iactivity';
import { IActivityDetailKind } from './iactivity-detail-kind';
import { IPractitioner } from './ipractitioner';
import { IFhirPatient } from './ifhir-patient';
import { IActivityStatus } from './iactivity-status';
import { IScheduled } from '@medlogic/shared/shared-interfaces';
// import { IAcceptanceCodeableConcept } from './iacceptance-codeable-concept';
// import { IScheduled } from './ischeduled';
// import { IAspectCodeableConcept } from './iaspect-codeable-concept';
// import { IDiuresisColorCodeableConcept } from './idiuresis-color-codeable-concept';
// import { IDiuresisQuantityCodeableConcept } from './idiuresis-quantity-codeable-concept';
// import { IEvacuationQuantityCodeableConcept } from './ievacuation-quantity-codeable-concept';
// import { IDecubitusPositionCodeableConcept } from './idecubitus-position-codeable-concept';
// import { IContributorOnActivity } from './icontributor-on-activity';

export interface IActivityDetail {
	activityDetailIdentifier: string;
	activityIdentifier: string;
	activityDetailKindIdentifier: string;
	activityDetailKind: IActivityDetailKind[];
	authorPractitionerIdentifier: string;
	practitioner: IPractitioner;
  practitioners?: IPractitioner[];
	subjectPatientIdentifier: string;
	patient: IFhirPatient[];
	activityStatusIdentifier: string;
	ActivityStatus: IActivityStatus;
	quantity: number;
  Scheduled: IScheduled;
  Practitioner: IPractitioner;
  instantiatesCanonical: string;
  instantiatesUri: string;
  reasonCode: string;
  reasonReference: string;
  goal: string;
  statusReason: string;
  doNotPerform: string;
  location: string;
  product: string;
  dailyAmount: number; //TODO: verificar se o tipo FLOAT pode ser utilizado aqui!
  description: string;
  scheduledIdentifier: string;
  tenantId: number;
  language: string;
  comments: string;
  // para dashboard
  prontuario?: string;
  Activity?: IActivity;
  ActivityDetailKind?: IActivityDetailKind;
  Patient?: IFhirPatient;
  icons?: any;
  behaviour?: string;

}
