import { EnActivityType } from './../../enum/fhir/en-activity-type.enum';
import { EnActivityStatus, IActivityDetail, IProgress, IActivityTypeCodeableConcept } from '@medlogic/shared/shared-interfaces';


export interface IActivityIcon {
	name: string;
	label: string;
	enabled: boolean;
}

export interface IActivity {
	activityIdentifier: string;
  activityDetails: IActivityDetail[]
  progress: IProgress[];
	activityName: string;
	milleniumId: number;
	medlogicId: number;
	activityDescription: string;
	note: string;
	outcomeCodeableConcept: string;
	outcomeReference: string;
	tenantId: number;
	language: string;
	// TODO: Implementar o status
	status?: EnActivityStatus;
	icons: IActivityIcon[];
	activityType: EnActivityType;
  currentProgress?: IProgress;
  currentActivityDetail?: IActivityDetail;
  ActivityTypeCodeableConcept?: IActivityTypeCodeableConcept;
}

  // calcHasOccurrence: boolean; // Indica se houve intercorrência ou alteração em sinais vitais. Deve ser populado, NÃO é preenchido automaticamente.
  // calcHasEvolutionOccurrence: boolean; // Indica se houve intercorrência. Deve ser populado, NÃO é preenchido automaticamente.
  // calcHasVitalSignOccurrence: boolean; // Indica se há sinais vitais fora da referência.
  // calcCodigo(thisPatient: IPatient): number; // É o código calculado. Há inconsistência na codificação, hora no prontuário, hora no código, hora apenas na identificacao2 esse campo calculado visa corrigir isso.
  // // TODO: Nomenclaturas do IDOSO.MEDLOGIC necessário padronizar no app e demais ambientes
  // situacaoHospede: boolean;
  // nomeResidente: string;
