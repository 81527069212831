import { EnEmailMessageStatus } from './../enum/en-email-message-status.enum';
export interface IEmailMessage {
    id: string;
    fromEmail: string;
    toEmails: string; // Separated by ","
    subject: string;
    htmlContent: string;
    textContent?: string;
    dtSend: Date;
    enEmailMessageStatus: EnEmailMessageStatus;
}
