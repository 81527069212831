import { IProductCodeableConcept } from './iproduct-codeable-concept';
import { IActivity } from './iactivity';

export interface IProductCodeableActivityIcons {
  activityIdentifier: string;
  productIconIdentifier: string;
  activity: IActivity[];
  productCodeableConcept: IProductCodeableConcept;
  display: string;
}
