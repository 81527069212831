export interface IEvolucaoEnfermagem {
  ocorrenciaNo: number;
nomeresidente:  any;
nascimento:  any;
nomeMae:  any;
identificacao1:  any;
identificacao2:  any;
prontuario:  any;
dataAniversario:  any;
dataEvolucao:  any;
admissao:  string;
cPF:  string;
idade:  any;
endereco:  string;
telefone:  string;
celular:  string;
email:  string;
pai:  string;
quarto:  string;
codigo:  any;
titulo:  any;
habilitado:  boolean;
dataInternacao:  any;
motivo:  string;
leitoHospitalar:  any;
dataAlta:  any;
diagnosticoFinal:  string;
desfecho:  string;
diasPermanenciaHospitalar:  any;
profissionalQueIdentificoulesao:  any;
ocorrenciaPRof:  any;
andar:  string;
dataIncidente:  any;
dtIncidente:  any;
localIncidente:  string;
hematomas:  boolean;
queratose:  boolean;
outros:  string;
internacaoRecente:  boolean;
terminalidade:  boolean;
dataAcionamento:  any;
dtAcionamento:  any;
horarioAcionamento:  any;
medicoPlantonista:  any;
medicoCAscata:  any;
oCorrMedico:  any;
cRM:  any;
tipoCodigo:  string;
queda:  boolean;
motivoAcionamento:  string;
transferenciaHospitalar:  boolean;
houveContatomedicotelemedicinaComMedicoExterno:  boolean;
centrocustoidoso:  string;
obito:  boolean;
horarioObito:  any;
horariopreenchimentoDECLARACAODEOBITO:  any;
dataAplicacao:  any;
dtAplicacao:  any;
enfermeiro:  any;
enfCascata:  any;
opcoesScore:  string;
necessidadeLavagem:  boolean;
local:  string;
dataLavagem:  any;
dtLavagem:  any;
observacoes:  string;
usaMarcapasso:  boolean;
dataImplantacao:  any;
dtImplantacao:  any;
dataultimaRevisao:  any;
dtUltimaRevisao:  any;
dataproximaRevisao:  any;
dtProximaRevisao:  any;
encontraemPrecaucao:  boolean;
motivoprecaucao:  string;
qualtipoprecaucao:  string;
coletaexame:  boolean;
dataultimaColetaexame:  any;
dtUltimaColeta:  any;
tipoexame:  string;
incontinenciaUrinaria:  boolean;
utilizaFraldas:  boolean;
periodoFraldas:  string;
utilizaAbsorvente:  boolean;
periodoAbsorvente:  string;
preferenciaspacienteCuidador:  string;
sWITCHPrefPacCuid:  any;
condicoesPele:  string;
sWITCHPele:  any;
capacidadeCognitva:  string;
sWITCHCognitiva:  any;
capacidadeMotora:  string;
sWITCHMotora:  any;
incontinencia:  string;
sWITCHIncontinencia:  any;
sWITCHEnvelhecimentoPele:  any;
escoreEscalaAUFA:  any;
envelhecimentoPele:  string;
rESULTADOOLD:  string;
rISCODAI:  any;
dataICIQSF:  any;
dtData:  any;
sexo:  string;
ComQueFrequenciaVocePerdeUrina:  string;
GostariamossaberquantidadeurinaQueVocePensaQuePerde:  string;
EmGeralQuantoQuePerderUrinaInterfereNaSuaVidaDiariaSelecioneUmNumeroEntre0NaoInterfere10InterfereMuito:  string;
iCIQEscore:  any;
anteschegarAoBanheiro:  boolean;
quandoTussoOuEspirro:  boolean;
quandoEstouDormindo:  boolean;
quandoEstouFazendoAtividadesFisicas:  boolean;
quandoTermineirurinarestouMeVestindo:  boolean;
semRazaoObvia:  boolean;
oTempoTodo:  boolean;
nunca:  boolean;
pontuacao3:  any;
pontuacao4:  any;
rESULTADOescala:  any;
uripen:  boolean;
cateterVesicaldemora:  boolean;
cistostomia:  boolean;
colostomia:  boolean;
gastrostomia:  boolean;
sondaNasoEnteral:  boolean;
ocorrenciaCVD:  boolean;
tipoCVD:  any;
necessidadetrocadispositivoCVD:  boolean;
ocorrenciaCIST:  boolean;
tipoCIST:  any;
necessidadetrocadispositivoCIST:  boolean;
ocorrenciaGAST:  boolean;
tipoGAST:  any;
necessidadetrocadispositivoGAST:  boolean;
ocorrenciaSNE:  boolean;
tipoSNE:  any;
necessidadetrocadispositivoSNE:  boolean;
possuiCarteiravacinacao:  boolean;
vacinasAtualizadas:  boolean;
qualVacinaPendente:  string;
ultimaDoseRecebidaDuplaAdultoDT:  any;
ultimaDoseRecebidaPneumococica23:  any;
ultimaDoseRecebidaInfluenza:  any;
ultimaDoseRecebidaCovid19:  any;
numerohiperglicemiaNoUltimoMes:  any;
numerohipoglicemiaNoUltimoMes:  any;
riscoPsiquiatrico:  boolean;
data:  any;
numeronotificacao:  any;
conduta:  string;
lesaoPorPressao:  boolean;
estagio:  string;
numeroNotificacaoLP:  any;
localLesaoPorPressao:  any;
lesaoPorFriccao:  boolean;
grau:  string;
numeroNotificacaoLF:  any;
localLesaoPorFriccao:  any;
dermatiteAssociadaincontinenciaDAI:  boolean;
categoria:  string;
numeroNotificacaoHMT:  any;
localLesao:  any;
cornoCutaneo:  boolean;
criteriosPlanoLilas:  any;
dataEvolucao7:  any;
dataEvolucao2:  any;
dataEvolucao3:  any;
dataEvolucao4:  any;
dataEvolucao6:  any;
dataEvolucao8:  any;
dataaplicacao:  any;
pontuacao5:  any;
dataEvolucao11:  any;
dataEvolucao12:  any;
duplaAdulto:  any;
pneumococica:  any;
influenza:  any;
covid19:  any;
dataEvolucao13:  any;
dataEvolucao14:  any;
dataEvolucao5:  any;
QuandoVocePerdeUrina:  any;
localLesaoDAI:  any;
resultado:  any;
executor:  any;
riscoFuga:  boolean;
altoRiscoQueda:  boolean;
requerIsolamento:  boolean;
tipoIsolamento:  string;
descreva:  string;
hipodermoclise:  boolean;
retornoHospitalar:  boolean;
dataPrevistaRetorno:  any;
}
